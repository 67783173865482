<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="news-title">
          <h6>
            {{ newsList.name }}
          </h6>
          <p>时间：{{ newsList.createDate }} 浏览：{{ newsList.hitCount }}次</p>
        </div>
        <div class="news-info" v-html="newsList.content"></div>
        <div class="next-next">
          <ul>
            <li @click="goPre" v-if="showPre">
              <a>上一篇:{{ showPre.name }}</a>
            </li>
            <li @click="goNext" v-if="showNext">
              <a>下一篇:{{ showNext.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      newsId: "",
      //当前新闻列表
      newsList: [],
      //当前版块新闻数据
      allNewsList: [],
      showNext: null,
      showPre: null,
      // info: [],
      index: 0,
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getNewsView() {
      this.$post(this.$api.NEWS_INFO.VIEW, {
        id: this.$route.query.id,
      }).then((res) => {
        this.newsList = res;
      });
    },
    getList() {
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 100,
          pageNo: 1,
        }).then((res) => {
          this.allNewsList = res.list;
          this.checkDetail();
        });
      }
    },
    checkDetail(oInfo) {
      let oId;
      if (oInfo !== undefined) {
        this.index = this.allNewsList.findIndex((item) => item.id == oInfo.id);
        oId = oInfo.id;
      } else {
        // this.info = this.$route.query.id;
        this.index = this.allNewsList.findIndex(
          (item) => item.id == this.$route.query.id
        );
        oId = this.$route.query.id;
      }
      if (this.index !== 0) {
        this.showPre = this.allNewsList[this.index - 1];
      } else {
        this.showPre = null;
      }
      if (this.index !== this.allNewsList.length - 1) {
        this.showNext = this.allNewsList[this.index + 1];
      } else {
        this.showNext = null;
      }
      if (!oId) return
      this.$post("newsview", {
        id: oId,
      }).then((res) => {
        this.newsList = res;
      });
    },
    goPre() {
      this.checkDetail(this.showPre);
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    goNext() {
      this.checkDetail(this.showNext);
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
  },
  mounted() {
    this.getNewsView();
    this.getList();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .news-title {
        text-align: center;
        margin-top: 30px;
        h6 {
          font-size: 20px;
          color: #363636;
          // height: 55px;
        }
        p {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
          border-top: 1px #dddddd solid;
          padding-top: 15px;
        }
      }
      .next-next {
        text-align: center;
        margin-top: 20px;
        padding-bottom: 20px;
        ul {
          display: inline-block;
          max-width: 100%;
          li {
            float: left;
            max-width: 100%;
            padding: 0 20px;
            height: 26px;
            border-radius: 15px;
            border: 1px #dddddd solid;
            margin: 0 5px 10px;
            box-sizing: border-box;
            a {
              display: block;
              line-height: 26px;
              text-align: center;
              font-size: 12px;
              color: #666666;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>